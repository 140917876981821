import React from "react"
import Layout from "../components/layout"
import SEO from "./../components/seo"
const p404 = () => (
  <Layout>
    <SEO title={`Erreur 404`} description="Cette page n'existe pas" canonical={process.env.GATSBY_URL_ACTUEL} robots="noindex" />
    <h1>ERREUR 404</h1>
    <p>Désolé, Cette page n’existe pas.</p>
    <p>Rendez-vous sur l’une de nos autres pages pour poursuivre votre visite.</p>
  </Layout>
)

export default p404
